<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Progress Dokumen Proyek" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Proyek *</label>
            <v-select :options="proyeks" @input="onChangeProyek" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">Item Dokumen Proyek *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Dokumen" :value="data.item_dokumen_proyek.nama" readonly :disabled="!data.id_proyek" @click="isModalItemProyekDokumenActive = true"/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" :disabled="!data.id_proyek" @click="isModalItemProyekDokumenActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">No. Pengajuan *</label>
            <vs-input class="w-full" v-model="data.no_pengajuan" placeholder="Generate Otomatis" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">No. Dokumen *</label>
            <vs-input class="w-full" v-model="data.no_dokumen"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Tanggal Pengajuan</label>
            <flat-pickr class="w-full" v-model="data.tgl_pengajuan"></flat-pickr>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Tanggal Estimasi Selesai</label>
            <flat-pickr class="w-full" v-model="data.tgl_est_selesai"></flat-pickr>
          </div>
          <div class="vx-col sm:w-1/4 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Tanggal Selesai</label>
            <flat-pickr class="w-full" v-model="data.tgl_selesai"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Status *</label>
            <v-select :options="[ 'PROSES','SELESAI']" :clearable="false" v-model="data.status"/>
          </div>
          <div class="vx-col sm:w-9/12 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Deskripsi Pengajuan *</label>
            <vs-input class="w-full" v-model="data.keterangan_pengajuan"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Keterangan</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan_status" />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs opacity-75">File</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" v-model="data.fileTemp"
                      @change="data.file = $event.target.files[0]"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs opacity-75">Foto Bukti *</label>
            <vs-input class="w-full" type="file" accept="image/*" v-model="data.fotoBuktiTemp"
                      @change="data.foto_bukti = $event.target.files[0]"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal item dokumen proyek-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Item Dokumen Proyek"
                :active="isModalItemProyekDokumenActive"
                v-on:update:active="isModalItemProyekDokumenActive = $event">
        <ItemDokumenProyek
          :selectable="true"
          :externalFilter="filterItemDokumenProyek"
          @selected="onSelectedModalItemDokumenProyek"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import ProgressDokumenProyekRepository from '@/repositories/proyek/progress-dokumen-proyek-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import ItemDokumenProyek from '@/views/pages/master/item-dokumen-proyek/ItemDokumenProyek'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'

export default {
  name: 'ProgressDokumenProyekAdd',
  components: {
    ValidationErrors,
    flatPickr,
    ItemDokumenProyek,
    'v-select': vSelect
  },
  props: ['isActive'],
  mounted () {
    this.getAllProyek()
  },
  computed: {
    filterItemDokumenProyek () {
      return this.data.id_proyek ? { id_proyek: this.data.id_proyek, group1: this.data.group1 } : null
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      isModalItemProyekDokumenActive: false,
      data: {
        group1: 'Dokumen Proyek',
        id_proyek: null,
        item_dokumen_proyek: {}
      },
      proyeks: []
    }
  },
  methods: {
    onChangeProyek () {
      this.data.item_dokumen_proyek = {}
      this.data.id_item_dokumen_proyek = null
    },

    onSelectedModalItemDokumenProyek (item) {
      this.data.item_dokumen_proyek = item
      this.data.id_item_dokumen_proyek = item.id
      this.isModalItemProyekDokumenActive = false
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      // append all data to formData
      const vue = this
      const formData = new FormData()

      const exceptKeys = ['item_dokumen_proyek', 'fileTemp', 'fotoBuktiTemp']
      Object.keys(vue.data).filter(key => !exceptKeys.includes(key)).forEach(key => {
        const value = vue.data[key]
        value == null ? formData.append(key, '') : formData.append(key, value)
      })

      ProgressDokumenProyekRepository.create(formData)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
